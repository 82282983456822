import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';

import Tooltip from '../../Tooltip';

const InputComponent = ({
  afterChangeHandler,
  autoComplete,
  containerClassName,
  decimalScale,
  disabled,
  displayLabel,
  field: { value },
  field,
  form: formikForm,
  form: { setFieldValue },
  helperText,
  helperTextLink,
  initialValue,
  inputClassName,
  inputRender,
  label,
  labelAlignment,
  labelClassName,
  labelColor,
  normalize,
  numberFormat,
  prefix,
  styledType,
  suffix,
  tooltip,
  tooltipCustomClass,
  tooltipLink,
  tooltipLinkText,
  tooltipSize,
  tooltipText,
  tooltipViewBoxSize,
  ...props
}) => {
  const changeHandler = event => {
    if (normalize) {
      const val = normalize(event.currentTarget.value);
      setFieldValue(field.name, val);
    } else {
      setFieldValue(field.name, event.currentTarget.value);
    }

    if (afterChangeHandler) {
      afterChangeHandler(
        event.currentTarget.value,
        field.value,
        formikForm,
        event.currentTarget
      );
    }
  };

  const changeHandlerNumberFormat = values => {
    const thisValue = typeof values.floatValue !== 'undefined' && values.value;
    setFieldValue(field.name, thisValue);

    if (afterChangeHandler) {
      afterChangeHandler(thisValue, field.value, formikForm);
    }
  };

  const getInputStyleType = btnType => {
    switch (btnType) {
      case 'line-box':
        return 'block w-full title text-xl mb-2';
      case 'default':
        return 'border border-grey-light rounded p-2 w-full';
      case 'no-styled':
        return '';
      default:
        return 'border border-grey-light rounded p-2';
    }
  };

  const inputClasses = getInputStyleType(styledType);

  // console.log(' - InputComponent', {value}, {initialValue}, {inputRender}, {name:field.name})

  // Will render plan input without wrappers, labels
  if (inputRender) {
    if (numberFormat) {
      return (
        <NumberFormat
          className={`${inputClasses} ${inputClassName}`}
          {...props}
          {...field}
          thousandSeparator
          prefix={prefix}
          suffix={suffix}
          disabled={disabled}
          onValueChange={changeHandlerNumberFormat}
          onChange={() => {}}
        />
      );
    }
    return (
      <input
        className={`${inputClasses} ${inputClassName}`}
        {...props}
        {...field}
        value={initialValue || value || ''}
        disabled={disabled}
        autoComplete={autoComplete ? 'on' : 'off'}
        onChange={changeHandler}
      />
    );
  }

  return (
    <div className={containerClassName}>
      <div className="flex flex-col items-stretch">
        {displayLabel && (
          <div className="flex mb-2 items-center">
            <label
              className={
                labelClassName ||
                `${labelColor} ${labelAlignment} text-xs text-grey-dark font-light block uppercase`
              }
              htmlFor={field.name}
            >
              {label}
              {helperText && (
                <>
                  {helperTextLink && (
                    <a
                      className="link-dntly-blue"
                      href={helperTextLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="normal-case ml-3">{helperText}</span>
                    </a>
                  )}
                  {!helperTextLink && (
                    <span className="normal-case ml-3">{helperText}</span>
                  )}
                </>
              )}
            </label>
            {tooltip ? (
              <Tooltip
                text={tooltip}
                size={tooltipSize}
                viewBoxSize={tooltipViewBoxSize}
                customClass={tooltipCustomClass}
                link={tooltipLink}
                linkText={tooltipLinkText}
              />
            ) : (
              <div style={{ minHeight: '26px' }} />
            )}
          </div>
        )}

        {numberFormat ? (
          <NumberFormat
            className={`${inputClasses} ${inputClassName}`}
            {...props}
            {...field}
            thousandSeparator
            decimalScale={decimalScale}
            prefix={prefix}
            suffix={suffix}
            disabled={disabled}
            onValueChange={changeHandlerNumberFormat}
            onChange={() => {}}
          />
        ) : (
          <input
            className={`${inputClasses} ${inputClassName}`}
            disabled={disabled}
            {...props}
            {...field}
            value={initialValue || value || ''}
            autoComplete={autoComplete ? 'on' : 'off'}
            onChange={changeHandler}
          />
        )}
      </div>
      <ErrorMessage
        component="div"
        className="text-sm text-red mb-2 mt-2"
        name={field.name}
      />
    </div>
  );
};

InputComponent.defaultProps = {
  containerClassName: 'mb-4',
  decimalScale: 2,
  disabled: false,
  displayLabel: true,
  inputClassName: '',
  inputRender: false,
  labelAlignment: '',
  labelColor: 'text-dark',
  numberFormat: false,
  prefix: '',
  suffix: '',
  tooltip: false,
  tooltipCustomClass: '',
  tooltipSize: 20,
  tooltipViewBoxSize: 24,
  helperTextLink: '',
};

InputComponent.propTypes = {
  afterChangeHandler: PropTypes.func,
  autoComplete: PropTypes.string,
  containerClassName: PropTypes.string,
  component: PropTypes.node,
  decimalScale: PropTypes.number,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  displayLabel: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  helperText: PropTypes.string,
  helperTextLink: PropTypes.string,
  inputClassName: PropTypes.string,
  inputRender: PropTypes.bool,
  label: PropTypes.string,
  labelAlignment: PropTypes.string,
  labelClassName: PropTypes.string,
  labelColor: PropTypes.string,
  maxLength: PropTypes.number,
  normalize: PropTypes.func,
  numberFormat: PropTypes.bool,
  prefix: PropTypes.string,
  styledType: PropTypes.string,
  suffix: PropTypes.string,
  tooltip: PropTypes.any,
  tooltipCustomClass: PropTypes.string,
  tooltipLink: PropTypes.string,
  tooltipLinkText: PropTypes.string,
  tooltipSize: PropTypes.number,
  tooltipText: PropTypes.string,
  tooltipViewBoxSize: PropTypes.number,
};

const FormikInput = props => {
  const { component, ...rest } = props;
  return <Field component={component || InputComponent} {...rest} />;
};

export default FormikInput;
