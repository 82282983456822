import { combineReducers } from 'redux';
import {
  SELECTED_FORM,
  RECEIVE_FORMS,
  REQUEST_FORMS,
  SET_FORM_QUERY,
} from './forms.actions';

export function selectedForm(state = {}, action) {
  switch (action.type) {
    case SELECTED_FORM:
      return action.form;
    default:
      return state;
  }
}

export function receiveFormQuery(state = {}, action) {
  switch (action.type) {
    case SET_FORM_QUERY:
      return action.query;
    default:
      return state;
  }
}

function forms(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    summary: {},
    orderBy: {},
  },
  action
) {
  switch (action.type) {
    case REQUEST_FORMS:
      return { ...state, isFetching: true, didInvalidate: false };
    case RECEIVE_FORMS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.forms,
        summary: action.summary,
        orderBy: action.orderBy,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

export function formsByAccount(state = {}, action) {
  switch (action.type) {
    case RECEIVE_FORMS:
    case REQUEST_FORMS:
      return {
        ...state,
        [action.account_identifier]: forms(
          state[action.account_identifier],
          action
        ),
      };
    default:
      return state;
  }
}

const formsReducer = combineReducers({
  formsByAccount,
  selectedForm,
  receiveFormQuery,
});

export default formsReducer;
