import React from 'react';
import { receiveFail } from '../donations/donations.actions';
import * as Queries from '../../library/query.module';
import { rebuildApiHeaders } from '../../library/functions';

export const SELECTED_FORM = 'SELECTED_FORM';
export function selectedForm(json) {
  return {
    type: SELECTED_FORM,
    form: json,
    receivedAt: Date.now(),
  };
}

export const SET_FORM_QUERY = 'SET_FORM_QUERY';
export function setFormQuery(query) {
  return {
    type: SET_FORM_QUERY,
    query,
  };
}

export const REQUEST_FORM = 'REQUEST_FORM';
export function requestForm(headers, formId) {
  return {
    type: REQUEST_FORM,
    headers,
    formId,
  };
}

export const RECEIVE_FORMS = 'RECEIVE_FORMS';
export function receiveForms(account_identifier, json) {
  return {
    type: RECEIVE_FORMS,
    account_identifier,
    forms: json.data,
    summary: json.summary,
    orderBy: json.params,
    receivedAt: Date.now(),
  };
}

export const REQUEST_FORMS = 'REQUEST_FORMS';
export function requestForms(account_identifier, headers) {
  return {
    type: REQUEST_FORMS,
    headers,
    account_identifier,
  };
}

export const EDIT_FORM = 'EDIT_FORM';
export function editForm(formId, headers) {
  return {
    type: EDIT_FORM,
    formId,
    headers,
  };
}

export function campaignForms(account, headers) {
  async function parseResponse(res) {
    return await res.json().then(r => r.data);
  }
  const query = { order_by: 'created_at', order: 'desc' };
  const url = Queries.getFormsQuery(account, 0, query, 100);
  return fetch(url, {
    method: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers: rebuildApiHeaders(headers),
  })
    .then(response => parseResponse(response))
    .catch(e => console.log('e', e));
}

export function fetchForms(account_identifier, headers, offset, query, limit) {
  if (!query) {
    query = {};
  }
  if (!offset) {
    offset = '0';
  }
  if (!limit) {
    limit = '20';
  }

  if (!query.order_by) {
    query = { ...query, order_by: 'created_at', order: 'desc' };
  }
  const url = Queries.getFormsQuery(account_identifier, offset, query, limit);

  return dispatch => {
    dispatch(requestForms(account_identifier, headers));
    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    })
      .then(function(response) {
        if (response.status !== 200) {
          return console.log('error');
        }
        response
          .json()
          .then(json => dispatch(receiveForms(account_identifier, json)))
          .then(dispatch(setFormQuery(query)));
        return response;
      })
      .catch(e => {
        console.log('e', e);
      });
  };
}

export function fetchForm(headers, account_id, formId) {
  return dispatch => {
    dispatch(requestForm(headers, account_id, formId));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/forms/${formId}.json?account_id=${account_id}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return console.log('error');
      }
      return response.json().then(json => dispatch(selectedForm(json.data)));
    });
  };
}

export function updateForm(account_identifier, headers, formId, updateValues) {
  return dispatch => {
    dispatch(editForm(formId, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/forms/${formId}.json?account_id=${account_identifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message, Date.now())));
      }
      return response
        .json()
        .then(
          json => dispatch(selectedForm(json.data)),
          dispatch(receiveFail('Form successfully saved.', Date.now())),
          dispatch(fetchForms(account_identifier, headers))
        );
    });
  };
}

export function createForm(account_identifier, headers, updateValues) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/forms.json?account_id=${account_identifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message, Date.now())));
      }
      return response
        .json()
        .then(
          json => dispatch(selectedForm(json.data)),
          dispatch(receiveFail('Form successfully saved.', Date.now())),
          dispatch(fetchForms(account_identifier, headers))
        );
    });
}

export function deleteForm(account_id, headers, formId) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/forms/${formId}.json?account_id=${account_id}`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message, Date.now())));
      }
      return response.json().then(dispatch(fetchForms(account_id, headers)));
    });
}

export function shouldFetchForms(state, account_identifier) {
  const forms = state.formsReducer.formsByAccount[account_identifier];
  if (!forms) {
    return true;
  }
  if (forms.isFetching || forms.items) {
    return false;
  }
}

export function fetchFormsIfNeeded(account_identifier, headers) {
  return (dispatch, getState) => {
    if (shouldFetchForms(getState(), account_identifier)) {
      return dispatch(fetchForms(account_identifier, headers));
    }
  };
}

export function resendEcard(headers, donation_identifier) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/donations/${donation_identifier}/send_ecard`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    if (response.status !== 200) {
      dispatch(receiveFail('An error ocurred when resending the e-card'));
    } else {
      dispatch(receiveFail('The e card has been resent successfully'));
    }
  };
}

export async function sendTestEcard(
  headers,
  account_id,
  title = '',
  content = '',
  footer = ''
) {
  const response = await fetch(
    `${process.env.DONATELY_API_V2_URL}/notifications/send_test?type=ecard`,
    {
      method: 'POST',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
      body: JSON.stringify({
        account_id,
        type: 'ecard',
        ecard_title: title,
        ecard_content: content,
        ecard_footer: footer,
      }),
    }
  );

  if (response.status !== 200) {
    return 'An error ocurred when sending the test e-card';
  }
  return 'The e card test has been sent successfully';
}
