import moment from 'moment-timezone';
import React from 'react';
import { Line } from 'rc-progress';
import currencyFormatter from 'currency-formatter';
import { uniq, get, isEqual } from 'lodash';
import { defaultThemes } from '../authenticated/forms2/mappers/defaultThemes';
import { FORM_UPGRADE_BETA_TESTERS, PRICING_PLANS } from './constants';

const configVariables = require('../../../config/config.json');

export function getWebflowUrl(urlType, webflowOptions) {
  const {
    accountId,
    campaignId,
    fundraiserId,
    formId,
    templateId,
  } = webflowOptions;

  const baseWebflowUrl = 'https://campaigns.donately.com';

  let returnUrl = baseWebflowUrl;
  switch (urlType) {
    case 'campaign':
      returnUrl = `${baseWebflowUrl}/${templateId}/cmp?account_id=${accountId}&campaign_id=${campaignId}`;
      break;
    case 'fundraiser':
      returnUrl = `${baseWebflowUrl}/${templateId}/fun?account_id=${accountId}&fundraiser_id=${fundraiserId}`;
      break;
    case 'teamFundraiser':
      returnUrl = `${baseWebflowUrl}/${templateId}/fun-team?account_id=${accountId}&fundraiser_id=${fundraiserId}`;
      break;
    case 'form':
      returnUrl = `${baseWebflowUrl}/donate?account_id=${accountId}&form_id=${formId}`;
      break;
    default:
      returnUrl = `${baseWebflowUrl}?account_id=${accountId}`;
  }
  // console.log('getWebflowUrl', { returnUrl }, { urlType }, { webflowOptions });
  return returnUrl;
}

export function getTaxYear() {
  const currentDate = moment();
  const thisYear = currentDate.format('YYYY');
  const previousYear = currentDate.subtract(1, 'Y').format('YYYY');
  const thisMonth = currentDate.format('MM');

  const taxYear = thisMonth < 11 ? previousYear : thisYear; // default to this year in November

  const yearOptions = [];
  for (let x = 0; x < 5; x += 1) {
    const year = moment(`${taxYear}-03-15`)
      .subtract(x, 'Y')
      .format('YYYY');
    yearOptions.push({ value: year, name: year });
  }

  return {
    taxYear,
    yearOptions,
  };
}

export function rebuildApiHeaders(headers, apiVersion = null) {
  const pxCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)_px2\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
  if (pxCookie) {
    Object.assign(headers, {
      'X-PX-Cookie': pxCookie,
    });
  }
  if (apiVersion && typeof apiVersion === 'string') {
    Object.assign(headers, {
      'Donately-Version': apiVersion,
    });
  }
  return headers;
}

export function getCampaignFundraisers(fundraisers, hasCampaignValue) {
  return fundraisers.filter(
    fundraiser =>
      (fundraiser.campaign?.id || fundraiser.campaignId) === hasCampaignValue
  );
}

export function formattedDate(date) {
  // console.log('formattedDate', {date}, (new Date(date).toLocaleDateString('en-US')))
  if (date) {
    return new Date(date).toLocaleDateString('en-US');
  }
}

export function formattedDateNoChangeTimezone(date) {
  if (date) {
    return moment
      .utc(date)
      .format()
      .split('T')[0];
  }
}

//
// options = {
// 'localtime': true/false (default true)
// 'seperator': / or - (default -)
// 'format': dmy or ymd or mdy (default ymd)
// 'time': true/false (default false)
// 'timezone': true/false (default false)
// 'offset': true/false (default false)
// }
export function formattedDateTime(date, options = {}) {
  if (date) {
    let zone = moment.tz.guess();
    if (!options.localtime) {
      zone = 'Etc/GMT+0';
    }

    let seperator = '-';
    if (options.seperator) {
      seperator = options.seperator;
    }

    let format = `YYYY${seperator}MM${seperator}DD`;
    if (options.format === 'dmy') {
      format = `DD${seperator}MM${seperator}YYYY`;
    } else if (options.format === 'mdy') {
      format = `MM${seperator}DD${seperator}YYYY`;
    }

    if (options.time) {
      format = `${format} h:mm:ss A`;
    }
    if (options.timezone) {
      format = `${format} z`;
    }
    if (options.offset) {
      format = `${format} (Z)`;
    }
    return moment(date)
      .tz(zone)
      .format(format);
  }
}
/*

  getDonatelyFormEmbed allows us to beta-test new form versions with some accounts
   - eventually we should just add this as an attribute of selectedAccount (in the API)

*/
export function getDonatelyFormEmbed(selectedAccount) {
  let formScriptSrc = null;

  Object.entries(configVariables.otherScripts).forEach(([i, scriptObject]) => {
    if (scriptObject.id === 'donately-form-js') {
      formScriptSrc = scriptObject.src;
    }
  });

  if (!formScriptSrc) {
    console.error('Error finding donately-form-js');
  }

  // console.log(
  //   'getDonatelyFormEmbed',
  //   { NODE_ENV: process.env.NODE_ENV },
  //   { FORM_UPGRADE_BETA_TESTERS },
  //   { 'BETA TESTER?': FORM_UPGRADE_BETA_TESTERS.includes(selectedAccount?.id) }
  // );

  if (FORM_UPGRADE_BETA_TESTERS.includes(selectedAccount?.id)) {
    if (process.env.NODE_ENV === 'development') {
      formScriptSrc =
        'https://s3.amazonaws.com/cdn.donately.com/core/staging/donately.min.js'; // staging new form
    } else {
      formScriptSrc =
        'https://s3.amazonaws.com/cdn.donately.com/core/staging/donately.min.js'; // staging new form
    }
  }

  return formScriptSrc;
}

export const getDefaultDonorPaysFeesForType = (
  enabledPaymentMethods,
  donorPaysFeesType,
  selectedAccount
) => {
  const DEFAULT_TRANSACTION_FEES = {
    cc: {
      processor_percent: 0.029,
      processor_fixed: 0.3,
      processor_cap: 0,
      dntly_percent: selectedAccount.donation_fee_percent,
      complex_calc: true,
    },
    card: {
      processor_percent: 0.029,
      processor_fixed: 0.3,
      processor_cap: 0,
      dntly_percent: selectedAccount.donation_fee_percent,
      complex_calc: true,
    },
    ach: {
      processor_percent: 0.008,
      processor_fixed: 0,
      processor_cap: 5.0,
      dntly_percent: selectedAccount.donation_fee_percent,
      complex_calc: true,
    },
    wallet: {
      processor_percent: 0.029,
      processor_fixed: 0.3,
      processor_cap: 0,
      dntly_percent: selectedAccount.donation_fee_percent,
      complex_calc: true,
    },
    paypal: {
      processor_percent: 0.029,
      processor_fixed: 0.3,
      processor_cap: 0,
      dntly_percent: selectedAccount.donation_fee_percent,
      complex_calc: true,
    },
  };

  const DEFAULT_PERCENT_FEES = {
    processor_percent: 0.03,
    processor_fixed: 0,
    processor_cap: 0,
    dntly_percent: 0,
    complex_calc: false,
  };

  const DEFAULT_FLAT_RATE_FEES = {
    processor_percent: 0,
    processor_fixed: 1.0,
    processor_cap: 0,
    dntly_percent: 0,
    complex_calc: false,
  };

  if (donorPaysFeesType === 'no') {
    return [];
  }

  const donorPaysFees = {};

  enabledPaymentMethods.forEach(paymentMethod => {
    let defaultFeesConfig = DEFAULT_TRANSACTION_FEES[paymentMethod];

    if (donorPaysFeesType === 'percent_fee') {
      defaultFeesConfig = {
        ...DEFAULT_PERCENT_FEES,
      };
    }

    if (donorPaysFeesType === 'flat_rate') {
      defaultFeesConfig = {
        ...DEFAULT_FLAT_RATE_FEES,
      };
    }

    donorPaysFees[paymentMethod] = defaultFeesConfig;
  });

  return donorPaysFees;
};

export function verifyDonorPaysFees(
  location,
  formValues,
  rawDonorPaysFees,
  selectedAccount,
  formAlerts,
  setFormAlerts
) {
  const { donorPaysFeesType: DpfType = null } = formValues;
  const verifyPlatformFee =
    DpfType === 'standard' || DpfType === 'custom_per_system';
  const paymentOptions = formValues.paymentOptions || {};
  const enabledPaymentOptions = Object.keys(paymentOptions).filter(
    paymentOption =>
      paymentOptions[paymentOption] && paymentOptions[paymentOption] === true
  );
  const donationFeePercent =
    selectedAccount && selectedAccount.donation_fee_percent;
  const thisFormAlert =
    'The Donor Pays Fees settings are not reflecting the correct donation fee percent.';
  const existingFormAlerts = formAlerts || [];
  const canUpdateFormAlerts =
    typeof existingFormAlerts === 'object' &&
    typeof setFormAlerts === 'function';
  const thisAlertExists = Object.values(existingFormAlerts).includes(
    thisFormAlert
  );

  if (DpfType === 'no' || enabledPaymentOptions.length === 0) {
    return false;
  }

  // get the default dpf for each payment type
  const defaultDpfByType = getDefaultDonorPaysFeesForType(
    enabledPaymentOptions,
    DpfType,
    selectedAccount
  );

  let objectifiedDpf = {};
  if (typeof rawDonorPaysFees === 'object') {
    objectifiedDpf = rawDonorPaysFees;
  } else {
    objectifiedDpf = safeJsonParse(rawDonorPaysFees);
  }

  let editableKeys = [];
  if (DpfType === 'flat_rate') {
    editableKeys.push('processor_fixed');
  } else if (DpfType === 'percent_fee') {
    editableKeys.push('processor_percent');
  } else {
    editableKeys = [
      'processor_percent',
      'processor_fixed',
      'processor_cap',
      'dntly_percent',
      'complex_calc',
    ];
  }

  const verifiedDpf = {
    preselected:
      typeof formValues.donorPaysFeesPreselected !== 'undefined'
        ? formValues.donorPaysFeesPreselected
        : objectifiedDpf.preselected,
  };
  Object.keys(defaultDpfByType).forEach(key1 => {
    verifiedDpf[key1] = {};
    Object.keys(defaultDpfByType[key1]).forEach(key2 => {
      if (editableKeys.includes(key2)) {
        if (!objectifiedDpf[key1]) {
          objectifiedDpf[key1] = defaultDpfByType[key1];
        }
        const typeOfKey = typeof objectifiedDpf[key1][key2];
        if (typeOfKey === 'boolean') {
          verifiedDpf[key1][key2] = objectifiedDpf[key1][key2];
        } else if (typeOfKey === 'undefined') {
          verifiedDpf[key1][key2] = defaultDpfByType[key1][key2];
        } else {
          verifiedDpf[key1][key2] = parseFloat(objectifiedDpf[key1][key2]);
        }
        // console.log(`--> ${key1}-${key2}`, {typeOfKey}, {objectifiedDpf:objectifiedDpf[key1][key2]}, {verifiedDpf:verifiedDpf[key1][key2]});
      } else {
        verifiedDpf[key1][key2] = defaultDpfByType[key1][key2];
      }
    });
  });

  // consoleLog(
  //   ' --> verifyDonorPaysFees',
  //   { location },
  //   { formValues },
  //   { rawDonorPaysFees },
  //   { objectifiedDpf },
  //   { verifyPlatformFee }
  // );

  if (!verifyPlatformFee || !donationFeePercent) {
    return verifiedDpf;
  }

  /* only continue if we need to verify the Platform Fees  */

  const {
    cc: { dntly_percent: dpfCC = null } = {},
    ach: { dntly_percent: dpfAch = null } = {},
    wallet: { dntly_percent: dpfWallet = null } = {},
    paypal: { dntly_percent: dpfPaypal = null } = {},
  } = verifiedDpf;

  let mismatch = false;
  if (dpfCC && parseFloat(dpfCC) !== parseFloat(donationFeePercent)) {
    mismatch = true;
  }
  if (dpfAch && parseFloat(dpfAch) !== parseFloat(donationFeePercent)) {
    mismatch = true;
  }
  if (dpfWallet && parseFloat(dpfWallet) !== parseFloat(donationFeePercent)) {
    mismatch = true;
  }
  if (dpfPaypal && parseFloat(dpfPaypal) !== parseFloat(donationFeePercent)) {
    mismatch = true;
  }

  if (mismatch && canUpdateFormAlerts && !thisAlertExists) {
    const existingFormAlertsArray = Object.values(existingFormAlerts);
    existingFormAlertsArray.push(thisFormAlert);
    setFormAlerts(existingFormAlertsArray);
  }
  if (!mismatch && canUpdateFormAlerts && thisAlertExists) {
    const existingFormAlertsArray = Object.values(
      existingFormAlerts.filter(alert => alert !== thisFormAlert)
    );
    setFormAlerts(existingFormAlertsArray);
  }

  return verifiedDpf;
}

export const getFloat = value => {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    return parseFloat(value.replace(/[^0-9.-]+/g, ''));
  }
  return 0;
};

export const centsToFormatted = (cents, code = 'USD') => {
  const formattedCurrency = code || 'USD';
  const formattedCode = formattedCurrency.toUpperCase();
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: formattedCode,
  }).format(getFloat(cents) / 100);
};

export function dollarsToFormatted(dollars) {
  return currencyFormatter.format(dollars, { code: 'USD' });
}

export const getCurrencySymbol = (currency = 'USD') => {
  const formattedCurrency = currency ? currency.toUpperCase() : 'USD';
  return (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: formattedCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
};

export const getAbbrCurrencySymbol = (currency = 'USD') => {
  const formattedCurrency = currency ? currency.toUpperCase() : 'USD';
  return (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: formattedCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/[A-Z0-9]/g, '')
    .trim();
};

export function formatStartDate(startDate) {
  return moment(startDate)
    .valueOf()
    .toString();
}

export function formatEndDate(endDate) {
  return (moment(endDate).valueOf() + 86399000).toString();
}

export function dollarsToCents(dollars) {
  return +dollars * 100;
}

export function createProgressBar(percentFunded) {
  return (
    <div className="progress-bar">
      <div className="h-4 py-1">
        <Line
          percent={percentFunded * 100 > 100 ? '100' : percentFunded * 100}
          strokeWidth="5"
          strokeColor="#3cd99e"
        />
      </div>
      <div className="text-black">{(percentFunded * 100).toFixed()}%</div>
    </div>
  );
}

export function parseUrl(urlSearchString) {
  let query;
  if (urlSearchString) {
    const search = urlSearchString.substring(1);
    query = JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  } else {
    query = {};
  }
  return query;
}

export const LANGUAGE_OPTIONS = [
  { value: 'en_US', name: 'English' },
  { value: 'es_ES', name: 'Spanish' },
  { value: 'fr_CA', name: 'French (Canada)' },
  { value: 'fr_FR', name: 'French (France)' },
  { value: 'it_IT', name: 'Italian' },
  { value: 'de_DE', name: 'German' },
  { value: 'sv_SE', name: 'Swedish' },
  { value: 'zh_HK', name: 'Chinese' },
];

export function safeJsonParse(json) {
  let parsed = {};
  if (json && typeof json === 'string') {
    try {
      parsed = JSON.parse(json);
    } catch (e) {
      // console.warn('safeJsonParse Error', {json}, {type:(typeof json)}, [json], {exists:(json !== "")})
    }
  }
  return parsed;
}

const recurringOptions = [
  { value: '1.month', label: 'Monthly Recurring' },
  { value: '2.month', label: 'Recurring every 2 months' },
  { value: '3.month', label: 'Recurring every 3 months' },
  { value: '4.month', label: 'Recurring every 4 months' },
  { value: '6.month', label: 'Recurring every 6 months' },
  { value: '12.month', label: 'Recurring once a year' },
  { value: '1.day', label: 'Daily recurring donation' },
  { value: '2.day', label: 'Recurring every 2 days' },
  { value: '3.day', label: 'Recurring every 3 days' },
  { value: '7.day', label: 'Recurring every 7 days' },
  { value: '14.day', label: 'Recurring every 14 days' },
  { value: '28.day', label: 'Recurring every 28 days' },
];

export const FORMIK_RECURRING_OPTIONS = recurringOptions.map(option => ({
  name: option.label,
  value: option.value,
}));

export const RECURRING_OPTIONS = recurringOptions;

export function formatFrequency(frequency) {
  if (!frequency) {
    return '';
  }

  if (frequency === '1.month') {
    return 'Recurring, Monthly';
  }
  if (frequency === '1.day') {
    return 'Recurring, Daily';
  }
  if (frequency === '7.day') {
    return 'Recurring, Weekly';
  }
  if (frequency === '3.month') {
    return 'Recurring, Quarterly';
  }

  return `Recurring every ${frequency.split('.')[0]} ${
    frequency.split('.')[1]
  }s`;
}

export function formatTitleFrequency(frequency) {
  if (frequency === '1.month') {
    return 'Monthly';
  }
  if (frequency === '1.day') {
    return 'Daily';
  }
  if (frequency === '7.day') {
    return 'Weekly';
  }
  if (frequency === '3.month') {
    return 'Quarterly';
  }
  if (frequency === '12.month') {
    return 'Annually';
  }

  return frequency;
}

export function formatExport(exportArray) {
  const formattedArray = [];
  for (let i = 0; i < exportArray.length; i += 1) {
    if (exportArray[i] === 'name_or_email' || exportArray[i] === 'last_name') {
      formattedArray.push('first_name');
      formattedArray.push('last_name');
    } else if (exportArray[i] === 'amount_in_cents') {
      formattedArray.push('amount_formatted');
    } else if (exportArray[i] === 'campaign') {
      formattedArray.push('campaign_title');
    } else if (exportArray[i] === 'fundraiser') {
      formattedArray.push('fundraiser_title');
    } else if (exportArray[i] === 'amount_raised_in_cents') {
      formattedArray.push('amount_raised_formatted');
    } else if (exportArray[i] === 'goal_in_cents') {
      formattedArray.push('goal_formatted');
    } else if (exportArray[i] === 'donation_amount_in_cents') {
      formattedArray.push('donation_amount_formatted');
    } else if (exportArray[i] === 'person_id') {
      formattedArray.push('person_first_name');
      formattedArray.push('person_last_name');
    } else if (exportArray[i] === 'fundraisers') {
      formattedArray.push('fundraiser_count');
    } else {
      formattedArray.push(exportArray[i]);
    }
  }
  return formattedArray;
}

export function setDefaultTheme(theme, attribute) {
  const selectedTheme = theme || 'classic';
  return defaultThemes[selectedTheme][attribute];
}

export function removeDefaultThemeValues(formValues) {
  // remove any default values to not add extra data to the form config since the form handles fallbacks
  if (!formValues) {
    return;
  }
  const clonedValues = { customTheme: { ...formValues.customTheme } };
  const formTheme = formValues.theme ? formValues.theme : 'classic';
  Object.keys(clonedValues.customTheme).forEach(val => {
    if (formValues.customTheme[val] === setDefaultTheme(formTheme, val)) {
      delete clonedValues.customTheme[val];
    }
  });
  return clonedValues;
}

export const formatWebhook = (apiName, version) => {
  if (
    apiName === 'account.donation.create.success' &&
    version === '2021-11-15'
  ) {
    return 'Donation Created';
  }
  if (apiName === 'account.donation.create.success' && !version) {
    return 'Donation Created';
  }

  if (apiName === 'account.donation.create.success') {
    return 'Donation Created';
  }

  if (apiName === 'account.campaign.create.success') {
    return 'Campaign Created';
  }

  if (apiName === 'account.fundraiser.create.success') {
    return 'Fundraiser Created';
  }

  if (apiName === 'account.donation.recurring.failed') {
    return 'Recurring Donation Failed';
  }

  if (apiName === 'account.person.pii.update.success') {
    return 'Donor Information Updated';
  }
  if (apiName === 'account.subscription.recur.failure') {
    return 'Failed Recurring Donation';
  }

  return apiName;
};

/*
 All Statuses:

 "",
 nil,
 "stripe_connected_true",
 "stripe_connect_deferred",
 "stripe_deauthorized",
 "stripe_deauthorized_by_account",
 "access_denied",
 "stripe_deauthorized_by_donately",
 "stripe_connected_false"

 */
export const deferredStatus = status => status === 'stripe_connect_deferred';
export const connectedStatus = status => status === 'stripe_connected_true';

export function notConnectedStatus(status) {
  return [
    '',
    null,
    'stripe_deauthorized',
    'stripe_deauthorized_by_account',
    'access_denied',
    'stripe_deauthorized_by_donately',
    'stripe_connected_false',
  ].includes(status);
}

export function upgradedAccount(subscriptionPlan) {
  return [
    PRICING_PLANS.Starter,
    PRICING_PLANS.Team,
    PRICING_PLANS.Enterprise,
  ].includes(subscriptionPlan);
}

/*
    scheduleTask() Schedule a recurring task to run
    taskFunction: (required) The function to run
    intervalInMilliSeconds: (optional, default 3000) How often to run in milliseconds
    endAfterXIntervals: (optional, default 0) Stop after x intervals, if 0 or less it never ends
  */
export const scheduleTask = (
  taskFunction,
  intervalInMilliSecondsArg,
  endAfterXIntervalsArg
) => {
  if (typeof taskFunction !== 'function') {
    console.warn('scheduleTask failed, task not found');
    return;
  }

  const intervalInMilliSeconds =
    typeof intervalInMilliSecondsArg === 'number'
      ? intervalInMilliSecondsArg
      : 3000;
  const endAfterXIntervals =
    typeof endAfterXIntervalsArg === 'number' ? endAfterXIntervalsArg : 3000;
  let intervalCount = 0;
  let intervalsLeft = endAfterXIntervals;
  const interval = setInterval(() => {
    intervalCount += 1;
    if (endAfterXIntervals > 0) {
      intervalsLeft -= 1;
      consoleLog(
        ` -|*|-> scheduleTask ${
          taskFunction.name
        } | intervalInSeconds: ${intervalInMilliSeconds /
          1000} | intervalsLeft: ${intervalsLeft}`,
        { codeArea: 'auth' }
      );
      if (intervalCount >= endAfterXIntervals) {
        clearInterval(interval);
      }
    } else {
      consoleLog(
        ` -|*|-> scheduleTask ${
          taskFunction.name
        } | intervalInSeconds: ${intervalInMilliSeconds / 1000}`,
        { codeArea: 'auth' }
      );
    }
    taskFunction();
  }, intervalInMilliSeconds);
};

/*
    timeUntilExpires() Measure the time between two timestamps
    expiresAtTimestamp: (required) The timestamp to measure to
    mode: (optional, default hours:minutes:seconds) The format to return the time measurement in
  */
export const timeUntilExpires = (expiresAtTimestampArg, mode = 'readable') => {
  const expiresAtTimestamp =
    expiresAtTimestampArg.toString().length === 10
      ? `${expiresAtTimestampArg}000`
      : expiresAtTimestampArg;
  const timeNow = new Date().getTime();

  const secondsDiff = Math.round((expiresAtTimestamp - timeNow) / 1000);
  let returnDiff = secondsDiff;

  if (mode === 'minutes') {
    returnDiff = secondsDiff / 60;
  } else if (mode === 'seconds') {
    returnDiff = secondsDiff;
  } else if (secondsDiff <= 0) {
    returnDiff = `-${secondsReadable(Math.abs(secondsDiff))}`;
  } else {
    returnDiff = secondsReadable(secondsDiff);
  }
  // consoleLog(` timeUntilExpires | timeNow:${timeNow} | expiresAtTimestamp: ${expiresAtTimestamp} | secondsDiff: ${secondsDiff} | returnDiff: ${returnDiff} | mode: ${mode}`, {'codeArea': 'verbose'})
  return returnDiff;
};

/* eslint-disable no-bitwise */
const secondsReadable = seconds => {
  // Hours, minutes and seconds
  /* eslint-disable no-bitwise */
  const hrs = ~~(seconds / 3600);
  const mins = ~~((seconds % 3600) / 60);
  const secs = ~~seconds % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';
  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`;
  }
  ret += `${mins}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;
  return ret;
};

/*
    consoleLog()
      Log stuff to the console selectively so it's easier to debug in production & development.
      It's much better than standard console.log because it's off by default, and you can turn on only certain ones at a time, even in production

    You can turn this on anytime in production by pasting the following into the browser console:
      window.dntlyDebug = {'auth':true, 'verbose':true, 'routes':true, 'components':true}
      OR just some of those, OR
      window.dntlyDebug = {'all':true}
    Remove the ones from the object or set to false, if you don't want to see them

    You can turn on all logging anywhere by setting this ENV variable:
    DEBUG_CONSOLE_LOGGING=true

    example uses:

      - To log something only when window.dntlyDebug{'auth':true} or env.DEBUG_CONSOLE_LOGGING=true
      consoleLog(`session expires in: '${diff}'`, {'codeArea':'auth'})

      - To log something anytime some logging is turned on
      consoleLog('you will be logged out in 5 seconds')

      - To log a bunch of stuff to the error log
      consoleLog({'object': 'value'}, ['array', 9], `this is a logged string`, {'logType':'warn'})

   */
export const consoleLog = (...theArgs) => {
  const logTypes = ['log', 'warn', 'error', 'info'];
  const codeAreas = ['auth', 'routes', 'components', 'verbose', 'all'];

  let debugging = false;
  if (window.dntlyDebug) {
    debugging = window.dntlyDebug;
  } else if (process.env.DEBUG_CONSOLE_LOGGING) {
    debugging = {};
    codeAreas.forEach(value => {
      debugging[value] = true;
    });
  }
  if (!debugging) return;

  let logType = logTypes[0];
  let codeArea = 'all';
  const ignoreIndexes = [];

  theArgs.forEach((value, index) => {
    if (
      typeof value === 'object' &&
      value.codeArea &&
      codeAreas.includes(value.codeArea)
    ) {
      codeArea = value.codeArea;
      ignoreIndexes.push(index);
    }
    if (
      typeof value === 'object' &&
      value.logType &&
      logTypes.includes(value.logType)
    ) {
      logType = value.logType;
      ignoreIndexes.push(index);
    }
  });

  const debugArgs = theArgs.filter(
    (value, index) => !ignoreIndexes.includes(index)
  );

  if (codeArea === 'all' || debugging.all || debugging[codeArea]) {
    if (logType === 'error') {
      console.error(...debugArgs);
    } else if (logType === 'warn') {
      console.warn(...debugArgs);
    } else if (logType === 'info') {
      console.info(...debugArgs);
    } else {
      console.log(...debugArgs);
    }
  }
};

//
// overwrite the Auth0 user profile name with the one in the Donately API DB
//  until we switch to Auth0 as the authority for the dashboard user profile
//
export const getUserName = userSettings => {
  const localStorageProfileName = localStorage.getItem('profile_name');
  const { email } = userSettings;
  if (localStorageProfileName && localStorageProfileName.indexOf('null') < 0) {
    return localStorageProfileName;
  }
  if (email) {
    return email;
  }

  return 'User';
};

export const getUserAvatar = () => {
  const localStorageProfileName = localStorage.getItem('profile_name');
  const profilePicture = localStorage.getItem('profile_picture');
  if (localStorageProfileName && localStorageProfileName.indexOf('null') >= 0) {
    return null;
  }

  return profilePicture;
};

export const isEmpty = obj => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  }

  return false;
};

/**
 * Checks if a query has changed with a deep comparison
 *
 * Note: offset option will be ignored as this could change.
 */
export const isQueryChanged = (prevQuery, query) => {
  const { offset: prevOffset, ...prevQueryNoOffset } = prevQuery;
  const { offset, ...queryNoOffset } = query;
  if (
    prevQueryNoOffset &&
    queryNoOffset &&
    isEqual(prevQueryNoOffset, queryNoOffset)
  ) {
    return false;
  }

  return true;
};

/**
 * Obtains an inner property from an object using lodash.
 *
 * @param {Object} obj - is the object to get the property from
 * @param {string} path - is the path of the property inside the object
 * @returns {any} the value of the property
 */
export const getInnerProp = (obj, path) => get(obj, path);

export const setDashboardView = view => {
  localStorage.setItem('dashboardView', view);
};

export const isDashboardView = view =>
  view && localStorage.getItem('dashboardView') === view;

// TODO use isAllowedIfMatchesSubscription instead
export const memberTeamView = (account, view) =>
  // if it's Free and team member on ORG view
  account === true && typeof view !== 'undefined' && view === 'team';

// TODO use isAllowedIfMatchesSubscription instead
export const memberOrgView = (account, view) =>
  // if it's Free and team member on ORG view
  account === true && typeof view !== 'undefined' && view === 'org';

/*
 Current Subscription Options

  Discontinued-Free (old legacy accounts before we had subscription)
  Free (no monthly fee)
  Team / Enterprise (monthly fee)
*/

/*
 Usage Examples:

 1) isValid when the user is a donately_team_member & dashboardView === 'team' OR this account is on the 'Team' or 'Enterprise' subscription
 isValid = isAllowedIfMatchesSubscription(this.props.currentUserSettings, this.props.selectedAccount, ['Team', 'Enterprise'])

 2) isValid when the user is a donately_team_member & dashboardView === 'team' OR this account is on the 'Team' or 'Enterprise' or 'Discontinued-Free' subscription
 isValid = isAllowedIfMatchesSubscription(this.props.currentUserSettings, this.props.selectedAccount, ['Team', 'Enterprise', 'Discontinued-Free'])

 3) isValid only when this account is on the 'Enterprise' plan, regardless of dashboardView
 isValid = isAllowedIfMatchesSubscription(null, this.props.selectedAccount, ['Enterprise'])

 3) canUpgrade only when this account is on the 'Free' or 'Discontinued-Free' plan, regardless of dashboardView
 canUpgrade = isAllowedIfMatchesSubscription(null, selectedAccount, ['Free', 'Discontinued-Free'])

*/

const shouldBeAllowed = (user, account, subscriptions, included) => {
  const userIsTeamMember = get(user, 'donately_team_member', false);
  const teamViewIsEnabled = localStorage.getItem('dashboardView') === 'team';

  if (teamViewIsEnabled && userIsTeamMember) {
    // team members should be allowed everything
    return true;
  }

  const plan = get(account, 'billing.subscription_plan', 'Free');

  if (typeof account === 'undefined')
    console.warn('should be allowed error - account undefined:', account);

  // console.log('should be allowed', { result: subscriptions.includes(plan), plan, subscriptions, userIsTeamMember, teamViewIsEnabled, included })

  return included
    ? subscriptions.includes(plan)
    : !subscriptions.includes(plan);
};

export const isRestrictedIfMatchesSubscription = (
  user,
  account,
  subscriptions
) => {
  const userIsTeamMember = get(user, 'donately_team_member', false);
  const teamViewIsEnabled = localStorage.getItem('dashboardView') === 'team';

  if (teamViewIsEnabled && userIsTeamMember) {
    // team members shouldn't be restricted
    return false;
  }

  const plan = get(account, 'billing.subscription_plan', 'Free');

  if (typeof account === 'undefined')
    console.warn('should be allowed error - account undefined:', account);

  return subscriptions.includes(plan);
};

export const isAllowedIfMatchesAccountIds = (user, account, accountsArray) => {
  const userIsTeamMember = get(user, 'donately_team_member', false);
  const teamViewIsEnabled = localStorage.getItem('dashboardView') === 'team';

  if (teamViewIsEnabled && userIsTeamMember) {
    // team members should be allowed everything
    return true;
  }

  return accountsArray.includes(account?.id);
};

export const isAllowedIfMatchesSubscription = (
  user,
  account,
  validSubscriptions
) => shouldBeAllowed(user, account, validSubscriptions, true);

export const isAllowedIfNotMatchesSubscription = (
  user,
  account,
  invalidSubscriptions
) => shouldBeAllowed(user, account, invalidSubscriptions, false);

export const isDonatelyTeamMember = user =>
  get(user, 'donately_team_member', false);

export const formatNeededFieldsStripe = fields => {
  const fieldMapping = {
    business_url: 'Business URL',
    email: 'Email',
    external_account: 'External Account',
    product_description: 'Product Description',
    support_phone: 'Support Phone',
    'tos_acceptance.date': 'Stripe Terms of Service',
    'tos_acceptance.ip': 'Stripe Terms of Service',
  };
  return uniq(fields.map(el => fieldMapping[el]));
};

export function isTrueBoolean(boolean) {
  if (typeof boolean === 'undefined') {
    return false;
  }
  if (boolean === 'true' || boolean === true) {
    return true;
  }
  return false;
}

export function isFalseBoolean(boolean) {
  if (typeof boolean === 'undefined') {
    return false;
  }
  if (boolean === 'false' || boolean === false) {
    return true;
  }
  return false;
}

export function canProcessDonations(selectedAccount) {
  const stripePublishableKey = get(
    selectedAccount,
    'processors.stripe.stripe_publishable_key',
    null
  );
  const paypalPublishableKey = get(
    selectedAccount,
    'processors.paypal.livemode',
    null
  );
  const canProcess = stripePublishableKey || paypalPublishableKey;
  // consoleLog('canProcessDonations', {selectedAccount}, {canProcess}, {stripePublishableKey}, {paypalPublishableKey})
  return !!canProcess;
}

export function isCorrectMode(selectedAccount) {
  const processorsLivemode = get(selectedAccount, 'processors.livemode', null);
  let correctLivemode = null;
  if (process.env.NODE_ENV === 'production') {
    correctLivemode = !!processorsLivemode;
  } else {
    correctLivemode = !processorsLivemode;
  }
  return correctLivemode;
}

export function getPaymentMethodsStatus(selectedAccount) {
  const propertyToFind =
    process.env.NODE_ENV === 'development'
      ? 'processors.paypal.testmode.paypal_connect_status'
      : 'processors.paypal.livemode.paypal_connect_status';
  const searchedProperty = get(selectedAccount, propertyToFind, '');
  const isPaypalConnected = searchedProperty === 'paypal_connected_true';
  const isStripeComplete =
    selectedAccount.stripe_connect_status !== 'stripe_connect_deferred' &&
    selectedAccount.stripe_connect_status !== 'stripe_connected_false';

  return [isStripeComplete, isPaypalConnected];
}
